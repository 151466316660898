import { HttpService } from '@core';

import { VerificationTarget } from './enums';

export class VerificationHttpService extends HttpService {
  async checkVerification(target: VerificationTarget, code: string) {
    return this.head(this.url(), { params: { target, code } });
  }

  async requestResetPassword(email: string) {
    return this.post(this.url('reset-password'), { email });
  }
}

export const verificationHttpService = new VerificationHttpService('verification');
