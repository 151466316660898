import { FunctionComponent } from 'react';

import { verificationHook } from '@hook';

export const VerifyEmailPage: FunctionComponent = () => {
  const result = verificationHook.useValidate();

  verificationHook.useVerifyEmail(result.valid, result.code);

  return <></>;
};
