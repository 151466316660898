import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PagePath } from '@common';
import { SnackEvent, VerificationTarget, credentialsHttpService, verificationHttpService } from '@service';

export class VerificationHook {
  useRedirect() {
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search);

    const pathname = window.location.pathname;
    const target = (query.get('target') ?? '') as VerificationTarget;
    const code = query.get('code') ?? '';

    useEffect(() => {
      if (pathname !== PagePath.Verification) {
        return;
      }

      if (Object.values(VerificationTarget).includes(target) === false || code === '') {
        return navigate(PagePath.Home, { replace: true });
      }

      switch (target) {
        case VerificationTarget.VerifyEmail:
          return navigate(PagePath.VerificationVerifyEmail, { replace: true, state: { target, code } });

        case VerificationTarget.ResetPassword:
          return navigate(PagePath.VerificationResetPassword, { replace: true, state: { target, code } });
      }
    }, [pathname, target, code, navigate]);
  }

  useValidate() {
    const location = useLocation();

    const state = location.state as { target: VerificationTarget; code: string };
    const [valid, setValid] = useState<null | boolean>(null);

    useEffect(() => {
      verificationHttpService.checkVerification(state.target, state.code).then((result) => {
        setValid(result.ok);
      });
    }, [state, setValid]);

    return { code: state.code, valid };
  }

  useVerifyEmail(valid: null | boolean, code: string) {
    const navigate = useNavigate();

    useEffect(() => {
      if (valid === null) {
        return;
      }

      if (valid === false) {
        SnackEvent.dispatchByWarning('유효하지 않은 인증 코드입니다.');
      }

      credentialsHttpService.verifyEmail(code).then((result) => {
        if (result.ok) {
          SnackEvent.dispatchByInfo('이메일 인증이 완료되었습니다.');
        }
      });

      return navigate(PagePath.Home, { replace: true });
    }, [valid, code, navigate]);
  }
}

export const verificationHook = new VerificationHook();
