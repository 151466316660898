import { FunctionComponent } from 'react';

import { verificationHook } from '@hook';
import { Outlet } from 'react-router-dom';

export const VerificationPage: FunctionComponent = () => {
  verificationHook.useRedirect();

  return <Outlet />;
};
